import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Layout from '../components/layout';
import PersonalBlog from '../containers/home';
import SEO from '../components/seo';

const PersonalBlogPage = () => {
  const { contentSeo } = useStaticQuery(
    graphql`
      query {
        contentSeo: allContentfulHomePage {
          edges {
            node {
              seoTitle
              seoDescription
            }
          }
        }
      }
    `
  );

  const { seoTitle, seoDescription } = contentSeo.edges[0].node;

  return (
    <Layout>
      <SEO title={seoTitle} description={seoDescription} />
      <PersonalBlog />
    </Layout>
  );
};

export default PersonalBlogPage;
